<template>
  <div id="Lift">
    <moModel :moData="moData"></moModel>
  </div>
</template>

<script>
import moModel from "../../home/components/models";
import { get } from "@get/http";
import { getQueryVariable } from "@get/util";
export default {
  data() {
    return {
      moData: {},
    };
  },
  components: {
    moModel,
  },
  created() {
    this.getIndexModelData();
  },
  methods: {
    async getIndexModelData() {
      /***
       * @method getIndexModelData 获取首页模块数据
       */
      let store_id = getQueryVariable("store_id");
      let id = getQueryVariable("s_id");
      let page_type = getQueryVariable("page_type");
      let { page_config } = await get("/index/mofang3", {
        data: {
          store_id,
          id,
          page_type,
        },
      });
      this.moData = page_config;
    },
  },
};
</script>

<style lang="sass" scoped></style>
